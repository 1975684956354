import { mapGetters } from 'vuex'
import effects from '@/cloudia.library/utils/effects'
import HttpClient from '@/cloudia.library/httpClient'
import ClientDB from '@/cloudia.library/clientDB'
import conf from '@/cloudia.library/conf'
import formatter from '@/cloudia.library/utils/formatter'

export default {
  data: () => ({
    HttpClient: new HttpClient(),
    Effects: effects,
  }),
  computed: {
    ...mapGetters({
      userInfo: 'loginInfo/userInfo',
      isAdmin: 'loginInfo/isAdmin',
      authToken: 'loginInfo/authToken',
      beauty: 'masters/beauty',
      costume: 'masters/costume',
      photo: 'masters/photo',
      meal: 'masters/meal',
      option: 'masters/option',
      planInfo: 'masters/planInfo',
      frame: 'masters/frame',
    })
  },
  filters: {
    dataFormatter: function (value) {
      if (!value) return ''
      return formatter.dataFormatter(value)
    },
    timeFormatter: function (value) {
      if (!value) return ''
      return value.substr(11, 5)
    }
  },
  methods: {
    loadMasters: function (masters) {
      const arry = masters || []
      return new Promise(resolve => {
        const promises = arry.map(m => {
          return new Promise(resolve => {
            this.HttpClient.get(`/api/${m.controller}/GetAll`, this.authToken).then(data => {
              const target = m.propName
              this.$store.dispatch('masters/loadMasters', { data, target }).then(() => {
                resolve()
              })
            })
          })
        })
        Promise.all(promises).then(() => {
          resolve()
        })
      })
    },
    showInvalidMessage: function (message, invalidList) {
      console.log('viewMixins.showInvalidMessage', message, invalidList)
      const msg = message || ''
      const list = invalidList || []
      var keys = Object.keys(list)

      console.log('showInvalidMessage', this.$refs, keys)

      this.$bvToast.toast(msg, {
        title: 'メッセージ',
        autoHideDelay: 5000,
        variant: 'danger'
      })

      keys.forEach(k => {
        if (list[k] === undefined) { return }
        console.log('showInvalidMessage', k)
        this.$refs[k].state = false
        const feedback = document.querySelector(`#${k}.invalid-feedback`)
        if (!feedback) { return }
        feedback.innerText = list[k]
      })
    },
    showSuccessMessage: function (message) {
      console.log('showSuccessMessage', message)
      this.$bvToast.toast(message, {
        title: 'メッセージ',
        autoHideDelay: 5000,
        variant: 'success'
      })
    },
    clearInvalidMessage: function () {
      var keys = Object.keys(this.$refs)
      keys.forEach(k => {
        this.$refs[k].state = null
        const feedback = document.querySelector(`#${k}.invalid-feedback`)
        if (!feedback) { return }
        feedback.innerText = ''
      })
    },
    scrollToTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    saveInputCache: function (propName, obj) {
      return new Promise(resolve => {
        const db = new ClientDB(conf.cacheStoreName)
        db.get(0).then((data) => {
          console.log('saveInputCache', data)
          const d = data || {}
          const v = d.value || {}

          v[propName] = obj
          db.register(0, v).then(() => {
            resolve()
          })
        })
      })
    },
    removeInputCache: function (propName) {
      return this.saveInputCache(propName, null)
    },
    findInputCache: function (propName) {
      return new Promise(resolve => {
        const db = new ClientDB(conf.cacheStoreName)
        db.get(0).then((data) => {
          if (data === null || data === undefined) {
            resolve(null)
            return
          }
          if (data.value === null || data.value === undefined) {
            resolve(null)
            return
          }
          const res = data.value[propName]
          resolve(res)
        })
      })
    },
    clearInputCache: function () {
      return new Promise(resolve => {
        const db = new ClientDB(conf.cacheStoreName)
        db.get(0).then((data) => {
          if (data === null || data === undefined) {
            resolve()
            return
          }
          db.register(0, null).then(() => {
            resolve()
          })          
        })
      })
    }
  }
}