export const isLogedIn = (state) => {
  console.log('LoginInfo.getters.isLogedIn', state.userInfo)
  return state.authToken !== ''
}

export const userInfo = (state) => {
  console.log('LoginInfo.getters.userInfo', state.userInfo)
  return state.userInfo
}

export const isAdmin = (state) => {
  console.log('LoginInfo.getters.isAdmin', state.userInfo)
  if (state.userInfo === undefined || state.userInfo.isAdministrator === undefined) { return false }
  return state.userInfo.isAdministrator
}

export const authToken = (state) => {
  return state.authToken
}
