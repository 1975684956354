import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ListView from '../views/ListView.vue'
import ReserveListView from '../views/ReserveListView.vue'
import EntryEditView from '../views/EntryEditView.vue'
import ReserveEditView from '../views/ReserveEditView.vue'
import FrameView from '../views/FrameView.vue'
import TempHoldView from '../views/TempHoldView.vue'
import CalendarView from '../views/CalendarView.vue'
import TransListView from '../views/TransListView.vue'

import CostumeCalendarView from '../views/CostumeCalendarView.vue'
import CostumeDataView from '../views/CostumeDataView.vue'


import loginInfo from '@/store/loginInfo'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  {
    path: '/preedit/:id',
    name: 'EntryEditView',
    component: EntryEditView
  },
  {
    path: '/reservelist',
    name: 'ReserveListView',
    component: ReserveListView
  },
  {
    path: '/edit/:id',
    name: 'ReserveEditView',
    component: ReserveEditView
  },
  {
    path: '/frame',
    name: 'FrameView',
    component: FrameView
  },
  {
    path: '/temphold',
    name: 'TempHoldView',
    component: TempHoldView
  },
  {
    path: '/calendar',
    name: 'CalendarView',
    component: CalendarView
  },
  {
    path: '/ccal',
    name: 'CostumeCalendarView',
    component: CostumeCalendarView
  },
  {
    path: '/translist',
    name: 'TransListView',
    component: TransListView
  },
  {
    path: '/cdata',
    name: 'CostumeDataView',
    component: CostumeDataView
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { allowAnonymous: true }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous) {
    next()
    return
  }

  store.dispatch('loginInfo/loadUserInfo').then(() => {

    console.log(loginInfo)

    if (loginInfo.state.authToken !== '') {
      next()
    } else {
      next({ name: 'login' })
    }
  })
})

export default router
