<template>
  <b-container fluid id="app" style="padding-left: 0; padding-right: 0;">
    <loading-effect />

    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-button variant="link" @click="onToggleSidebar(false)" v-if="this.showSidebar">＜＜</b-button>
      <b-button variant="link" @click="onToggleSidebar(true)" v-if="!this.showSidebar">＞＞</b-button>

      <b-navbar-brand href="#">七五三予約管理システム</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" v-if="isLogedIn">

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ userInfo.displayName }}</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-row no-gutters>
      <b-col lg="2" md="3" class="sidebar" v-if="isLogedIn && showSidebar">

        <b-list-group>

          <router-link to="/">
            <b-list-group-item class="d-flex">ホーム</b-list-group-item>
          </router-link>

          <router-link to="/preedit/0">
            <b-list-group-item class="d-flex">仮予約新規入力</b-list-group-item>
          </router-link>

          <router-link to="/list">
            <b-list-group-item class="d-flex">仮予約一覧</b-list-group-item>
          </router-link>

          <router-link to="/reservelist">
            <b-list-group-item class="d-flex">本予約一覧</b-list-group-item>
          </router-link>

          <router-link to="/calendar">
            <b-list-group-item class="d-flex">カレンダー</b-list-group-item>
          </router-link>

          <router-link to="/temphold">
            <b-list-group-item class="d-flex">衣裳仮押さえ</b-list-group-item>
          </router-link>

          <router-link to="/ccal">
            <b-list-group-item class="d-flex">衣裳カレンダー</b-list-group-item>
          </router-link>
          <router-link to="/cdata">
            <b-list-group-item class="d-flex">衣裳データ</b-list-group-item>
          </router-link>
<!--
          <router-link to="/">
            <b-list-group-item class="d-flex">衣裳マスタメンテ</b-list-group-item>
          </router-link>

          <router-link to="/">
            <b-list-group-item class="d-flex">美容マスタメンテ</b-list-group-item>
          </router-link>

          <router-link to="/">
            <b-list-group-item class="d-flex">写真マスタメンテ</b-list-group-item>
          </router-link>
-->
          <router-link to="/frame">
            <b-list-group-item class="d-flex">予約枠設定</b-list-group-item>
          </router-link>

          <router-link to="/translist">
            <b-list-group-item class="d-flex">祈祷転送</b-list-group-item>
          </router-link>

        </b-list-group>
      </b-col>

      <b-col class="content">
        <router-view />
      </b-col>

    </b-row>

  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import LoadingEffect from './components/LoadingEffect.vue'

export default {
  name: 'App',
  components: { LoadingEffect },
  data: () => ({
    showSidebar: true,
  }),
  computed: {
    ...mapGetters({
      isLogedIn: 'loginInfo/isLogedIn',
      userInfo: 'loginInfo/userInfo'
    })
  },
  methods: {
    onToggleSidebar: function (t) {
      this.$set(this, 'showSidebar', t)
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.sidebar {
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  height: calc(100vh - 55px);
  background-color: #343a40!important;
}

.content {
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  height: calc(100vh - 55px);
  overflow: scroll;
}

html, body {
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

div.card-body {
  padding: 5px;
}

div.card-header {
  padding: 4px 20px;
}

input[type="number"] {
  text-align: right;
}
</style>
