import Vue from 'vue'
import * as types from './mutation-types'

export default {
  [types.SET_LOGIN_INFO](state, { userInfo, authToken }) {
    console.log(types.SET_LOGIN_INFO, userInfo, authToken)
    Vue.set(state, 'userInfo', userInfo)
    Vue.set(state, 'authToken', authToken)
  }
}
