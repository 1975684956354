import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ReserveEdit',
  mixins: [viewMixin],
  data: () => ({
    id: null,
    form: {
      size: {}
    },
    masters: ['beauty',/* 'costume',*/ 'photo', 'planInfo', 'frame', 'option' /*,'meal' */],
    showBasicInfo: true,
    showCostume: false,
    showBeauty: false,
    showPhoto: false,
    showMeal: false,
    showOption: false,
    childCountList: [
      { text: '１人', value: "1" },
      { text: '２人', value: "2" },
    ],
    masterFilterText: '',
    currentMaster: '',
    currentMasterList: [],
    currentPrayerId: 0,
    masterDispNames: [
      { code: 'beauty', text: '美容' },
      { code: 'costume', text: '衣裳' },
      { code: 'photo', text: '写真' },
      { code: 'meal', text: '食事' },
      { code: 'option', text: 'プラン' },
    ]
  }),
  watch: {
    $route: function () {
      console.log('watch.$route')
      this.initialize()
    }
  },
  computed: {
    findMasterDispName() {
      if (this.currentMaster === '') { return '' }
      const m = this.masterDispNames.find(x => x.code == this.currentMaster)
      return m.text
    },
    filteredPlanInfo: function () {
      if (this.planInfo == []) { return [] }
      return this.planInfo.map(p => {
        return {
          text: p.title,
          value: p.id
        }
      })
    },
    filteredFrame: function () {
      return this.frame.filter(f => {
        return f.frameDate == this.form.kitouDateStr
      }).map(f => {
        return {
          text: f.timeOnly,
          value: f.id
        }
      })
    },
    filteredCurrentMasterList() {
      if (this.masterFilterText === '') { return this.currentMasterList }
      return this.currentMasterList.filter(m => {
        return m.title.indexOf(this.masterFilterText) !== -1
      })
    }
  },
  mounted: function () {
    this.Effects.loadStart()
    this.id = this.$route.params.id
    this.initialize()
  },
  methods: {
    initialize: function () {
      this.Effects.loadStart()

      this.$store.dispatch('masters/loadMasters', this.masters, this.authToken).then(() => {
        this.HttpClient.get(`/api/v1/Reserve/Find?id=${this.id}`, this.authToken).then((data) => {
          this.$set(this, 'form', data.payload)
          this.Effects.loadComplete()
        })
      })
    },
    onTabClick: function (index) {
      this.showBasicInfo = index === 0
      this.showCostume = index === 1
      this.showBeauty = index === 2
      this.showPhoto = index === 3
      this.showMeal = index === 4
      this.showOption = index === 5
    },
    onMasterRemoveButtonClick(pid, name, mid) {

      const ret = window.confirm('この品目を削除します。よろしいですか?')

      if (!ret) { return; }

      const list = pid === 0 ?
        this.form[name] :
        this.form.prayers.find(p => p.id === pid)[name]
      
      const index = list.findIndex((v) => v.masterId === mid);
      list.splice(index, 1);
    },
    onMasterAddButtonClick(pid, name) {
      console.log(pid, name)

      const promise = name === 'costume'
        ? this.$store.dispatch('masters/loadReservedCostume', this.form.kitouDateStr, this.authToken) 
        : new Promise((resolve) => { resolve() })

      promise.then(() => {
        const list = this[name].map(m => {
          m.isSelected = false
          return m
        })

        this.currentMaster = name
        this.currentPrayerId = pid
        this.$set(this, 'currentMasterList', list)
        this.$bvModal.show('modal-master')
      })
    },
    onItemAddButtonClick() {

      const list = this.currentPrayerId === 0 ?
        this.form[this.currentMaster] :
        this.form.prayers.find(p => p.id === this.currentPrayerId)[this.currentMaster]

      const ids = list.map(x => x.masterId)
      const target = this.currentMasterList.filter(m => {
        return m.isSelected && !ids.includes(m.masterId)
      })
      
      list.push(...target.map(l => {
        l.quantity = 1
        return l 
      }))
      
      this.$bvModal.hide('modal-master')

      this.currentMaster = ''
      this.currentPrayerId = 0
      this.$set(this, 'currentMasterList', [])
    },
    onRegisterButtonClick() {
      this.Effects.loadStart()

      this.clearInvalidMessage()

      const regiter = this.id !== 0 ?
        this.HttpClient.put(`/api/v1/reserve/update?id=${this.id}`, this.form, this.authToken) :
        this.HttpClient.post('/api/v1/reserve/create', this.form, this.authToken)

      regiter.then((data) => {
        if (!data.isValid) {
          this.Effects.loadComplete()
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          return
        }

        this.$set(this, 'id', data.payload.id)

        this.initialize()

        this.Effects.loadComplete()
        this.showSuccessMessage(`本予約の更新処理が完了しました。`)
      })
    },
    onCancelButtonClick() {
      const ret = window.confirm('この予約をキャンセルします。よろしいですか?')
      if (!ret) { return; }
      this.HttpClient.delete(`/api/v1/entry/delete?id=${this.id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('キャンセル処理が完了しました。')

        this.$router.push({ name: 'ReserveListView' })
      })

    },
    onSendmailClick() {
      const ret = window.confirm('予約メールを送信します。よろしいですか?')
      if (!ret) { return }

      this.HttpClient.post(`/api/v1/reserve/sendmail?id=${this.id}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('メール送信処理が完了しました。')
      })

    },
    onSendCostumeWarningMailClick() {
      const ret = window.confirm('衣装の未予約注意メールを送信します。よろしいですか?')
      if (!ret) { return }

      this.HttpClient.post(`/api/v1/reserve/SendCostumeWarningMail?id=${this.id}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('メール送信処理が完了しました。')
      })

    },

    onParentCancelClick(entryId) {
      let ret = window.confirm('保護者の着付け予約をキャンセルします。\nこの処理は元に戻せません。よろしいですか?')
      if (!ret) { return }

      ret = window.confirm('このままキャンセルすると本当に元に戻せませんが、よろしいですか?')
      if (!ret) { return }

      this.Effects.loadStart()

      this.HttpClient.post(`/api/v1/reserve/ParentCancel?id=${entryId}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.initialize()

        this.showSuccessMessage('キャンセル処理が完了しました。')
        this.Effects.loadComplete()
      })

    },

    onChildCancelClick(prayerId) {
      let ret = window.confirm('指定のお子様の予約をキャンセルします。\nこの処理は元に戻せません。よろしいですか?')
      if (!ret) { return }

      ret = window.confirm('このままキャンセルすると本当に元に戻せませんが、よろしいですか?')
      if (!ret) { return }

      this.Effects.loadStart()

      this.HttpClient.post(`/api/v1/reserve/ChildCancel?id=${prayerId}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.initialize()

        this.showSuccessMessage('キャンセル処理が完了しました。')
      })


    }
  }
}