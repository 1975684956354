import * as types from './mutation-types'
import HttpClient from '@/cloudia.library/httpClient'

export const loadMasters = ({ commit }, list, authToken) => {
  return new Promise(resolve => {
    const promises = list.map(l => {
      return new Promise(resolve => {
        const httpClient = new HttpClient()
        httpClient.get(`/api/v1/${l}/GetAll`, authToken).then(data => {
          commit(types.LOAD_MASTERS, {id: l, data: data.payload})
          resolve()
        })
      })
    })
    Promise.all(promises).then(() => {
      resolve()
    })
  })

}

export const loadReservedCostume = ({ commit }, dt, authToken) => {
  return new Promise(resolve => {
    const httpClient = new HttpClient()
    httpClient.get(`/api/v1/costume/GetAllPreserved?dt=${dt}`, authToken).then(data => {
      commit(types.LOAD_MASTERS, {id: 'costume', data: data.payload})
      resolve()
    })
  })
}