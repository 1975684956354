import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ListView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetDateFrom: '',
      targetDateTo: '',
      appliName: '',
      mailAddress: '',
      telNo: '',
    },
    rec: [],
  }),
  mounted: function () {
    this.Effects.loadStart()

    const y = (new Date()).getFullYear();
    this.$set(this.form, 'targetDateFrom', `${y}-09-01`)
    this.$set(this.form, 'targetDateTo', `${y}-12-15`)

    this.Effects.loadComplete()
  },
  computed: {

  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Entry/Search', this.form, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    },
  }
}
