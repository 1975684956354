import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'EntryEdit',
  mixins: [viewMixin],
  data: () => ({
    id: null,
    form: {

    },
    masters: [ 'frame' /*,'meal' */],
    childCountList: [
      { text: '１人', value: "1" },
      { text: '２人', value: "2" },
    ],
    childAgeList: [
      { text: '三歳', value: "3" },
      { text: '五歳', value: "5" },
      { text: '七歳', value: "7" },
      { text: '初宮', value: "0" },
      { text: 'その他(成人式など)', value: "99" },
    ],
  }),
  watch: {
    $route: function () {
      console.log('watch.$route')
      this.initialize()
    }
  },
  computed: {
    filteredFrame: function () {
      if (this.form.frameDateStr === '') { return [] }
      return this.frame.filter(f => {
        return f.frameDate == this.form.frameDateStr
      }).map(f => {
        return {
          text: f.timeOnly,
          value: f.id + ''
        }
      })
    },
    disableKeys: function () {
      console.log('disableKeys', this.id, this.id !== 0 && this.id !== null)
      return this.id !== 0 && this.id !== null
    }
  },
  mounted: function () {
    this.Effects.loadStart()
    this.initialize()
  },
  methods: {
    initialize: function () {
      this.id = window.parseInt(this.$route.params.id, 10)
      console.log(this.id)
      this.Effects.loadStart()

      this.$store.dispatch('masters/loadMasters', this.masters, this.authToken).then(() => {
        this.HttpClient.get(`/api/v1/Entry/Find?id=${this.id}`, this.authToken).then((data) => {
          this.$set(this, 'form', data.payload)
          this.Effects.loadComplete()
        })
      })
    },
    onChildCountChanged() {
      if (this.disableKeys) { return }
      if (this.form.numberOfChildren === '') {
        this.$set(this.form, 'childrenTimes', [])
        return
      }

      const len = window.parseInt(this.form.numberOfChildren, 10)
      const ctimes = this.form.childrenTimes || []
      const c = ctimes.length

      if (c === len) { return }

      if (c > len) {
        this.form.childrenTimes.splice(len - c, c - len) 
      }
      else
      {
        console.log(len,c)
        for (let i = c; i < len; i++)
        {
          this.form.childrenTimes.push({item3:i + ''})
        }
      }
    },
    onRegisterButtonClick() {
      this.Effects.loadStart()

      const regiter = this.id !== 0 ?
        this.HttpClient.put(`/api/v1/entry/update?id=${this.id}`, this.form, this.authToken) :
        this.HttpClient.post('/api/v1/entry/create', this.form, this.authToken)

      regiter.then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        if (this.id === 0) {
          const id = data.payload
          this.$set(this, 'id', id)
          this.$router.push({ name: 'EntryEditView', params: { id: id } })
          this.showSuccessMessage(`仮予約の登録処理が完了しました。`)
          return
        }
        this.Effects.loadComplete()
        this.showSuccessMessage(`仮予約の更新処理が完了しました。`)
      })
    },
    onCancelButtonClick() {
      const ret = window.confirm('この仮予約をキャンセルします。よろしいですか?')
      if (!ret) { return; }

      this.HttpClient.delete(`/api/v1/entry/delete?id=${this.id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('キャンセル処理が完了しました。')

        this.$router.push({ name: 'EntryEditView', params: { id: 0 } })
      })
    },
    onSendmailClick() {
      const ret = window.confirm('仮予約メールを送信します。よろしいですか?')
      if (!ret) { return }

      this.HttpClient.post(`/api/v1/entry/sendmail?id=${this.id}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('メール送信処理が完了しました。')
      })

    },
  }
}