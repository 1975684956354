import viewMixin from '@/cloudia.library/mixins/viewMixins'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  mixins: [viewMixin],
  data: () => ({
    rec: [],
    year: 0,
    historyList: [],
    totalPlanCount: 0,
    totalNormalCount: 0,
    totalTempPlanCount: 0,
    totalTempNormalCount: 0
  }),
  mounted: function () {
    this.Effects.loadStart()

    console.log('this.isLogedIn',this.isLogedIn)

    const firstYear = 2022 // 七五三プラン開始年（西暦）
    const dt = new Date()
    const currentYear = dt.getFullYear()
    const hist = []

    for (let i = firstYear; i <= currentYear; i++) {
      hist.push(i)
    }
    this.historyList = hist.reverse()

    if (!this.isLogedIn) {
      this.$router.push({ name: 'login' })
      return
    }

    this.getHomeData(currentYear).then(() => {
      this.Effects.loadComplete()
    })

  },
  computed: {
    counterHeader: function () {
      return `${this.year}年 七五三予約状況`
    },
    ...mapGetters({
      isLogedIn: 'loginInfo/isLogedIn',
    })
  },
  methods: {
    onSearchClick: function () {

    },
    getHomeData: function (y) {
      return new Promise(resolve => {
        this.$set(this, 'year', y)

        this.HttpClient.get(`/api/v1/Entry/ReservedCount?y=${y}`, this.authToken).then((data) => {
          this.$set(this, 'rec', data.payload)
          
          let pc = 0
          let nc = 0
          let tpc = 0
          let tnc = 0 

          this.rec.forEach(r => {
            pc += r.map(m => m.planCount).reduce((a, c) => a + c)
            nc += r.map(m => m.normalCount).reduce((a, c) => a + c)
            tpc += r.map(m => m.tempPlanCount).reduce((a, c) => a + c)
            tnc += r.map(m => m.tempNormalCount).reduce((a, c) => a + c)
          })
          
          this.$set(this, 'totalPlanCount', pc)
          this.$set(this, 'totalNormalCount', nc)
          this.$set(this, 'totalTempPlanCount', tpc)
          this.$set(this, 'totalTempNormalCount', tnc)

          resolve()
        })
      })
    },
  }
}
