import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'FrameView',
  mixins: [viewMixin],
  data: () => ({
    targetYm: '',
    rec: {},
    showBeauty: true,
    showCostume: true,
    showPhoto: true,
    viewMode: '1',
  }),
  mounted: function () {
    this.Effects.loadComplete()
  },
  computed: {
    timesCount: function () {
      if (!this.rec.timeDispList) {return 1}
      return this.rec.timeDispList.length
    },
    showOption: function () {
      return this.viewMode === '1'
    },
    showCapacity: function () {
      return this.viewMode === '2'
    },
    showList: function () {
      return this.viewMode === '3'
    },
    showCalendar: function () {
      return this.viewMode === '1' || this.viewMode === '2'
    }
  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Calendar/Search', this.targetYm, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    }
  }
}
