import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    beauty: [],
    costume: [],
    photo: [],
    meal: [],
    planInfo: [],
    frame: [],
    option: []
  },
  mutations: mutations,
  actions: actions,
  getters: getters
}
