import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'TempHoldView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetDate: '',
      selectableAge3: false,
      selectableAge5: false,
      selectableAge7: false,
      selectableBoy: false,
      selectableGirl: false,
      selectableParent: false
    },
    selectableList: [],
    reservedList: [],
    searchText: '',
    memo: '',
    insideTargetDate: '',
  }),
  mounted: function () {
    this.Effects.loadStart()
    this.Effects.loadComplete()
  },
  computed: {
    filteredSelectableList: function () {
      if (this.searchText === '') { return this.selectableList }
      const list = this.selectableList.filter(m => {
        return m.insideCodes.indexOf(this.searchText) !== -1
      })
      return list
    },
    filteredReservedList: function () {
      if (this.searchText === '') { return this.reservedList }
      const list = this.reservedList.filter(m => {
        return m.itemName.indexOf(this.searchText) !== -1
      })
      return list
    },
    statusMemo: function () {
      return this.memo !== ''
    }
  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Costume/FindReservableCostumeByDay', this.form, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)
        this.$set(this, 'insideTargetDate', data.payload.targetDate)
        this.$set(this, 'selectableList', data.payload.selectable)
        this.$set(this, 'reservedList', data.payload.reserved)
        this.Effects.loadComplete()
      })
    },
    onPrereserveClick: function (id) {
      this.Effects.loadStart()
      console.log(id);
      const data = {
        form: this.form,
        masterId: id,
        memo: this.memo,
        targetDate: this.insideTargetDate
      }

      this.HttpClient.post('/api/v1/Costume/Prereserve', data, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)
        this.onSearchClick()
        this.Effects.loadComplete()
      })
    },
    onDeleteReserveClick: function (id) {

      if (!window.confirm('仮押さえを解除します。\nよろしいですか？')) {
        return;
      }

      this.Effects.loadStart()

      this.HttpClient.delete('/api/v1/Costume/DeletePrereserve?id=' + id, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        const deletedId = data.payload

        const index = this.reservedList.findIndex(m => {
          return m.prereserveId === deletedId
        })

        this.reservedList.splice(index, 1)

        this.Effects.loadComplete()
      })
    }
  }
}
