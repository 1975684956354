<template>
  <div id="loading" v-show="visible">
    <div class="loadingView" style="margin-top: 30%">
      <div class="wBall" id="wBall_1"><div class="wInnerBall"></div></div>
      <div class="wBall" id="wBall_2"><div class="wInnerBall"></div></div>
      <div class="wBall" id="wBall_3"><div class="wInnerBall"></div></div>
      <div class="wBall" id="wBall_4"><div class="wInnerBall"></div></div>
      <div class="wBall" id="wBall_5"><div class="wInnerBall"></div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingEffect',
  data: () => ({
    visible: false
  })
}
</script>
<style scoped>
#loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
    background-color: rgba(128, 128, 128, 0.5);
}

.loadingView {
    position: relative;
    width: 78px;
    height: 78px;
    margin: auto;
}

    .loadingView .wBall {
        position: absolute;
        width: 74px;
        height: 74px;
        opacity: 0;
        transform: rotate(225deg);
        -o-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        animation: orbit 3.0225s infinite;
        -o-animation: orbit 3.0225s infinite;
        -ms-animation: orbit 3.0225s infinite;
        -webkit-animation: orbit 3.0225s infinite;
        -moz-animation: orbit 3.0225s infinite;
    }

        .loadingView .wBall .wInnerBall {
            position: absolute;
            width: 10px;
            height: 10px;
            background: rgb(0,0,0);
            left: 0px;
            top: 0px;
            border-radius: 10px;
        }

    .loadingView #wBall_1 {
        animation-delay: 0.656s;
        -o-animation-delay: 0.656s;
        -ms-animation-delay: 0.656s;
        -webkit-animation-delay: 0.656s;
        -moz-animation-delay: 0.656s;
    }

    .loadingView #wBall_2 {
        animation-delay: 0.133s;
        -o-animation-delay: 0.133s;
        -ms-animation-delay: 0.133s;
        -webkit-animation-delay: 0.133s;
        -moz-animation-delay: 0.133s;
    }

    .loadingView #wBall_3 {
        animation-delay: 0.2665s;
        -o-animation-delay: 0.2665s;
        -ms-animation-delay: 0.2665s;
        -webkit-animation-delay: 0.2665s;
        -moz-animation-delay: 0.2665s;
    }

    .loadingView #wBall_4 {
        animation-delay: 0.3995s;
        -o-animation-delay: 0.3995s;
        -ms-animation-delay: 0.3995s;
        -webkit-animation-delay: 0.3995s;
        -moz-animation-delay: 0.3995s;
    }

    .loadingView #wBall_5 {
        animation-delay: 0.533s;
        -o-animation-delay: 0.533s;
        -ms-animation-delay: 0.533s;
        -webkit-animation-delay: 0.533s;
        -moz-animation-delay: 0.533s;
    }

@keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        transform: rotate(180deg);
        animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        transform: rotate(300deg);
        animation-timing-function: linear;
        origin: 0%;
    }

    30% {
        opacity: 1;
        transform: rotate(410deg);
        animation-timing-function: ease-in-out;
        origin: 7%;
    }

    39% {
        opacity: 1;
        transform: rotate(645deg);
        animation-timing-function: linear;
        origin: 30%;
    }

    70% {
        opacity: 1;
        transform: rotate(770deg);
        animation-timing-function: ease-out;
        origin: 39%;
    }

    75% {
        opacity: 1;
        transform: rotate(900deg);
        animation-timing-function: ease-out;
        origin: 70%;
    }

    76% {
        opacity: 0;
        transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        transform: rotate(900deg);
    }
}

@-o-keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        -o-transform: rotate(180deg);
        -o-animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        -o-transform: rotate(300deg);
        -o-animation-timing-function: linear;
        -o-origin: 0%;
    }

    30% {
        opacity: 1;
        -o-transform: rotate(410deg);
        -o-animation-timing-function: ease-in-out;
        -o-origin: 7%;
    }

    39% {
        opacity: 1;
        -o-transform: rotate(645deg);
        -o-animation-timing-function: linear;
        -o-origin: 30%;
    }

    70% {
        opacity: 1;
        -o-transform: rotate(770deg);
        -o-animation-timing-function: ease-out;
        -o-origin: 39%;
    }

    75% {
        opacity: 1;
        -o-transform: rotate(900deg);
        -o-animation-timing-function: ease-out;
        -o-origin: 70%;
    }

    76% {
        opacity: 0;
        -o-transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        -o-transform: rotate(900deg);
    }
}

@-ms-keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        -ms-transform: rotate(180deg);
        -ms-animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        -ms-transform: rotate(300deg);
        -ms-animation-timing-function: linear;
        -ms-origin: 0%;
    }

    30% {
        opacity: 1;
        -ms-transform: rotate(410deg);
        -ms-animation-timing-function: ease-in-out;
        -ms-origin: 7%;
    }

    39% {
        opacity: 1;
        -ms-transform: rotate(645deg);
        -ms-animation-timing-function: linear;
        -ms-origin: 30%;
    }

    70% {
        opacity: 1;
        -ms-transform: rotate(770deg);
        -ms-animation-timing-function: ease-out;
        -ms-origin: 39%;
    }

    75% {
        opacity: 1;
        -ms-transform: rotate(900deg);
        -ms-animation-timing-function: ease-out;
        -ms-origin: 70%;
    }

    76% {
        opacity: 0;
        -ms-transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        -ms-transform: rotate(900deg);
    }
}

@-webkit-keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        -webkit-transform: rotate(180deg);
        -webkit-animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        -webkit-transform: rotate(300deg);
        -webkit-animation-timing-function: linear;
        -webkit-origin: 0%;
    }

    30% {
        opacity: 1;
        -webkit-transform: rotate(410deg);
        -webkit-animation-timing-function: ease-in-out;
        -webkit-origin: 7%;
    }

    39% {
        opacity: 1;
        -webkit-transform: rotate(645deg);
        -webkit-animation-timing-function: linear;
        -webkit-origin: 30%;
    }

    70% {
        opacity: 1;
        -webkit-transform: rotate(770deg);
        -webkit-animation-timing-function: ease-out;
        -webkit-origin: 39%;
    }

    75% {
        opacity: 1;
        -webkit-transform: rotate(900deg);
        -webkit-animation-timing-function: ease-out;
        -webkit-origin: 70%;
    }

    76% {
        opacity: 0;
        -webkit-transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        -webkit-transform: rotate(900deg);
    }
}

@-moz-keyframes orbit {
    0% {
        opacity: 1;
        z-index: 99;
        -moz-transform: rotate(180deg);
        -moz-animation-timing-function: ease-out;
    }

    7% {
        opacity: 1;
        -moz-transform: rotate(300deg);
        -moz-animation-timing-function: linear;
        -moz-origin: 0%;
    }

    30% {
        opacity: 1;
        -moz-transform: rotate(410deg);
        -moz-animation-timing-function: ease-in-out;
        -moz-origin: 7%;
    }

    39% {
        opacity: 1;
        -moz-transform: rotate(645deg);
        -moz-animation-timing-function: linear;
        -moz-origin: 30%;
    }

    70% {
        opacity: 1;
        -moz-transform: rotate(770deg);
        -moz-animation-timing-function: ease-out;
        -moz-origin: 39%;
    }

    75% {
        opacity: 1;
        -moz-transform: rotate(900deg);
        -moz-animation-timing-function: ease-out;
        -moz-origin: 70%;
    }

    76% {
        opacity: 0;
        -moz-transform: rotate(900deg);
    }

    100% {
        opacity: 0;
        -moz-transform: rotate(900deg);
    }
}

</style>
