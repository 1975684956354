import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ListView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetFrom: '',
      targetTo: '',
      name: '',
      mailAddress: '',
      tel: '',
      itemName: '',
      includeDeleted: false,
      sortMode: '0'
    },
    infoList: [],
    rec: [],
    showCostumeDetail: false,
    showBeautyDetail: true,
    showPhotoDetail: false,
    showMealDetail: false
  }),
  mounted: function () {
    this.Effects.loadStart()
    const y = (new Date()).getFullYear();
    this.$set(this.form, 'targetFrom', `${y}-09-01`)
    this.$set(this.form, 'targetTo', `${y}-12-15`)
    this.Effects.loadComplete()
  },
  computed: {

  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Reserve/Search', this.form, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    },
    onDownloadManageListClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Reserve/DownloadManageList', this.form, this.authToken).then((data) => {
        console.log(data)
        let bin = atob(data)
        let buf = new ArrayBuffer(bin.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i != bin.length; ++i) {
          view[i] = bin.charCodeAt(i) & 0xFF
        }
        const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const elem = document.getElementById('download_link')
        elem.href = window.URL.createObjectURL(blob)
        elem.click()
        this.Effects.loadComplete()
      })
    },
    onDownloadReceiptClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Reserve/DownloadReceiptList', this.form, this.authToken).then((data) => {
        console.log(data)
        let bin = atob(data)
        let buf = new ArrayBuffer(bin.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i != bin.length; ++i) {
          view[i] = bin.charCodeAt(i) & 0xFF
        }
        const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const elem = document.getElementById('download_receipt_link')
        elem.href = window.URL.createObjectURL(blob)
        elem.click()
        this.Effects.loadComplete()
      })
    },
    onDownloadCostumeSlipClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Reserve/DownloadCostumeSlipList', this.form, this.authToken).then((data) => {
        console.log(data)
        let bin = atob(data)
        let buf = new ArrayBuffer(bin.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i != bin.length; ++i) {
          view[i] = bin.charCodeAt(i) & 0xFF
        }
        const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const elem = document.getElementById('download_costume_slip_link')
        elem.href = window.URL.createObjectURL(blob)
        elem.click()
        this.Effects.loadComplete()
      })
    },
    onTransIdClick: function (id) {
      this.Effects.loadStart()
      this.$router.push({ name: 'ReserveEditView', params: { id: id } })
    },
    onSendRemindarMailClick: function (id) {
      const ret = window.confirm('リマインダメールを送信します。\nよろしいですか？')
      if (!ret) { return }
      this.HttpClient.post(`/api/v1/reserve/SendRemainderMail?id=${id}`, '', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('リマインダメール送信処理が完了しました。')
      })
    }
  }
}
