/**
 * Loggerのログレベル定義
 */
const logLevel = {
  debug: 0,
  info: 10,
  warn: 20,
  error: 30
}

/**
 * 出力対象ログレベル
 */
const minLogLevel = logLevel.debug

/**
 * デバッグ用コンソールの使用有無
 */
const enableConsole = true

const defaultDBName = 're753'
const loggerStoreName = 'logger'
const cacheStoreName = 'cache'
const loginInfoStoreName = 'loginInfo'
const logPostUrl = '/api/logger/store'

// add by yam for login screen
const beforeLoginInfoStoreName = 'ddv2_beforeLoginInfo'

const storeVersion = 1 // need countup to change db structures

const storeNameList = [
  loggerStoreName,
  cacheStoreName,
  loginInfoStoreName,
  beforeLoginInfoStoreName
]

export default {
  logLevel,
  minLogLevel,
  enableConsole,
  defaultDBName,
  loggerStoreName,
  cacheStoreName,
  loginInfoStoreName,
  logPostUrl,
  storeNameList,
  storeVersion,
  beforeLoginInfoStoreName
}
