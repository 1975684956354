import * as types from './mutation-types'

export const setUserInfo = ({ commit }, { userInfo, authToken }) => {
  commit(types.SET_LOGIN_INFO, { userInfo, authToken })
  
  window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
  window.localStorage.setItem('authToken', authToken);
}

export const loadUserInfo = ({ commit }) => {
  return new Promise((resolve) => {
    const data = {
      userInfo: {},
      authToken: ''
    }
    const j = window.localStorage.getItem('userInfo')
    const t = window.localStorage.getItem('authToken')

    if (j && t) {
      data.userInfo = JSON.parse(j)
      data.authToken = t
    }

    commit(types.SET_LOGIN_INFO, data)
    resolve()
  })
}