import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'Login',
  mixins: [viewMixin],
  data: () => ({
    rec: {
      userId: '',
      password: ''
    },
  }),
  mounted: function () {
    this.Effects.loadComplete()
  },
  methods: {
    onLoginClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/User/TryLogin', this.rec, '').then((data) => {
        
        console.log(data, data.isValid, data.authToken == "")

        if (!data.payload.isValid || data.payload.authToken == "") {
          alert(data.payload.summaryMessage)
          this.Effects.loadComplete()
          return
        }

        console.log(data.payload)

        this.$store.dispatch('loginInfo/setUserInfo', data.payload)

        this.Effects.loadComplete()
        this.$router.push({ name: 'home' })
        
      
      })
    }
  }
}