import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'TransListView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetFrom: '',
      targetTo: '',
      includeLinked: false
    },
    headerCheck: false,
    rec: [],
  }),
  mounted: function () {
    this.Effects.loadStart()

    const y = (new Date()).getFullYear();
    this.$set(this.form, 'targetFrom', `${y}-09-01`)
    this.$set(this.form, 'targetTo', `${y}-12-15`)

    this.Effects.loadComplete()
  },
  computed: {

  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/reserve/TransList', this.form, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    },
    onHeaderCheckChanged: function () {
      for (let r of this.rec) {
        r.selected = !this.headerCheck
      }
      this.$set(this, 'rec', this.rec)
      // this.$set(this, 'headerCheck', !this.headerCheck)
    },
    onRegisterClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/reserve/Transfer', this.rec, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage('転送処理が完了しました。')

        this.onSearchClick()

      })
    }
  }
}
