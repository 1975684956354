import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'CostumeCalendarView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetFrom: `${(new Date()).getFullYear}-09-01`,

    },
    rec: {
      
    },
    searchText: '',
  }),
  mounted: function () {
    this.Effects.loadStart()

    const dt = new Date()
    const y = dt.getFullYear()
    this.form.targetFrom = `${y}-09-01`
    
    this.getCalendar().then(() => {
      this.Effects.loadComplete()
    })
    
  },
  computed: {
    filteredRows: function () {
      if (this.rec['rows'] == undefined) { return [] }
      if (this.searchText === '') { return this.rec.rows }

      return this.rec.rows.filter(m => {
        return m.master.insideCodes.indexOf(this.searchText) !== -1
      })

    }

  },
  methods: {

    getCalendar: function () {

      return new Promise(resolve => {

        const url = `/api/v1/Costume/GetCostumeCalendar?dt=${this.form.targetFrom}`

        return this.HttpClient.get(url, this.authToken).then(data => {
          if (!data.isValid) {
            this.showInvalidMessage(data.summaryMessage, data.invalidList)
            this.Effects.loadComplete()
            return
          }

          console.log('getCalendar', data.payload)

          this.$set(this, 'rec', data.payload)
          resolve()
        })
      })
    },
    onTransWeekClick: function (days) {
      const dt = new Date(this.form.targetFrom)
      console.log(dt)
      dt.setDate(dt.getDate() + days)

      const y = dt.getFullYear()
      const m = ('0' + (dt.getMonth() + 1)).substr(-2, 2)
      const d = ('0' + (dt.getDate())).substr(-2, 2)

      this.$set(this.form, 'targetFrom', `${y}-${m}-${d}`)
      this.Effects.loadStart()
      
      this.getCalendar().then(() => {
        this.Effects.loadComplete()
      })


    }
  }
}