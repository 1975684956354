import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ListView',
  mixins: [viewMixin],
  data: () => ({
    form: {
      targetFrom: '',
      targetTo: '',
    }
  }),
  mounted: function () {
    this.Effects.loadStart()
    const y = (new Date()).getFullYear();
    this.$set(this.form, 'targetFrom', `${y}-09-01`)
    this.$set(this.form, 'targetTo', `${y}-12-15`)
    this.Effects.loadComplete()
  },
  computed: {

  },
  methods: {
    onDownloadManageListClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/Reserve/DownloadCostumeData', this.form, this.authToken).then((data) => {
        console.log(data)
        let bin = atob(data)
        let buf = new ArrayBuffer(bin.length)
        let view = new Uint8Array(buf)
        for (let i = 0; i != bin.length; ++i) {
          view[i] = bin.charCodeAt(i) & 0xFF
        }
        const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const elem = document.getElementById('download_link')
        elem.href = window.URL.createObjectURL(blob)
        elem.click()
        this.Effects.loadComplete()
      })
    },
  }
}
