import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'FrameView',
  mixins: [viewMixin],
  data: () => ({
    infoList: [],
    infoId: 0,
    rec: [],
  }),
  mounted: function () {
    this.Effects.loadStart()
    this.HttpClient.get('/api/v1/PlanInfo/GetAll', this.authToken).then((data) => {
      this.$set(this, 'infoList', data.payload)
      this.Effects.loadComplete()
    })
  },
  computed: {
    filteredPlanInfo: function () {
      return this.infoList.map(l => {
        return {
          text: l.title,
          value: l.id,
        }
      })
    }
  },
  methods: {
    onSearchClick: function () {

      this.Effects.loadStart()

      this.HttpClient.get('/api/v1/Frame/find?id=' + this.infoId, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        console.log('onSearchClick', data.payload)

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    },
    onUpdateClick: function () {
      this.Effects.loadStart()

      this.HttpClient.put('/api/v1/Frame/update?id=' + this.infoId, this.rec, this.authToken).then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.$set(this, 'rec', data.payload)
        this.Effects.loadComplete()
      })
    }
  }
}
