export const beauty = (state) => {
  return state.beauty
}
export const costume = (state) => {
  return state.costume
}
export const photo = (state) => {
  return state.photo
}
export const meal = (state) => {
  return state.meal
}
export const option = (state) => {
  return state.option
}
export const planInfo = (state) => {
  return state.planInfo
}

export const frame = (state) => {
  return state.frame
}